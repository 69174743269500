.productCard{
    min-height: 380px;
    width:229px;
    border:0.1px solid lightgray ;
    padding:12px;
  }

  .productCard:hover{
    border: 0.1px solid lightgray !important;
  }
  .prices-container {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
  
  .discount-price {
    width: 48%; /* Adjust as needed */
  }
  
  .original-price {
    width: 48%; /* Adjust as needed */
    text-align: right;
    color: #9ca3b0;
  }
  
  .productCard .top_notch{
    /* height:21.5px; */
    height:32px;
    padding-right: 12px;
    background:#0e948d;
  }
  .long_description ul
  {
    list-style: disc;
    margin-left: 17px;
  }
  .productCard .top_notch p {
    margin-top:2px;
    font-size: 15px;
  }

  .productCard .card_add_btn{
    /* outline:1px solid green; */
    color:green;
    border: 1px solid green;
  }

  .product_img{
    width:100%;
  }
  .variants_options a > span
  {
    /* background: #F3BABF; */
    /* color: #000; */
    font-weight: 500;
    border-radius: 8px;
    padding: 4px 6px;
    margin-right: 5px;
    background: transparent;
    color: #000;
    border: 1px solid #aaa;
  }
  .variants_options a > .active
  {
    /* background: #70151d; */
    /* color: #fff; */
    font-weight: 800;
    background:transparent;
    border: 2px solid #000;
    color: #000;
  }
  .variant_in_slider.variants_options 
  {
    font-size:12px !important;
    height: 55px;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 55px;
    padding: 5px 0;
  }
  .variant_in_slider.variants_options a > span
  {
    border-radius: 4px;
    padding: 2px 3px;
    margin: 0 4px 4px 0;
    max-height: 24px;
  }

  .product_img img{
    height:174px ;
    width: 174px;
  }
  @media (max-width: 768px) {
    .text-lg {
      font-size: 15px !important; 
    }
  } 
  .productCard .product_name{
    font-weight:380;
    font-size:15px;
    line-height:20px;
  }
  .gray_text{
    color:#9CA3B0;
    font-weight:300;
    font-size:13px;
  }

  .out_stock_text{
    color: rgb(209, 45, 45);
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .margin_end_custom{
    margin-right: 10px !important;
  }

  .popup-content{
    width: 40% !important;
    border-radius: 9px;
  }

  .card_bottom .card_add_btn{
    width: 150px;
    margin-left: 40px;
  }

  /* tablet------- */
  @media screen and (max-width:900px){
    .brandCard{
      min-height: 340px;
      width:244.6px;
      border:0.1px solid lightgray ;
      padding:12px;
    }
    .catwise_product_card{
      min-height: 340px;
      width:253px;
      border:0.1px solid lightgray ;
      padding:12px;
    }
  }

  @media screen and (max-width:900px){
    .popup-content{
      width: 65% !important;
      border-radius: 9px;
    }
  }

  /* mobile ---------*/
  @media screen and (max-width:600px){
    .productCard .top_notch{
      height: 24px;
    }
    
    .productCard .top_notch p{
      font-size: 12px;
    }
    .discount_price {
      width: 30%; 
      font-size: 12px !important;
    }
    .bottom_items{
      text-align:center;
    }              

    .actual_price {
      width: 30%; /* Adjust as needed */
      text-align: right;
      color: #9ca3b0;
      font-size: 10px !important;
    }
    .brandCard{
      min-height: 380px;
      width:149.6px;
      border:0.1px solid lightgray ;
      padding:12px;
    }

    .catwise_product_card{
      min-height: 340px;
      width:253px;
      border:0.1px solid lightgray ;
      padding:12px;
    }

    .card_bottom .card_add_btn{
      width: 65px;
    }

    .popup-content{
      width: 85% !important;
      border-radius: 9px;
    }
    .product_img img {
      height: auto;
      width: 140px;
    }
}
@media screen and (max-width:400px){
  .productCard {
    min-height: 300px;
    width: 152px;
  }
}

