.footer_bottom_content_alignment_width{
    width:98px !important;
}

.footer_bottom_parent{
    background-color: #FCFCFC;
}

.footer_bottom_parent .bottom_center_div{
    width: 88% !important;
}

.category_list_container{
    width: 100%;
    max-width: 480px;
}

.carousel_heading {
  color: #fff!important;
  background: #CC0201;
  border-radius: 50px;
  padding: 15px 20px; 
  text-transform: uppercase;
}


@media screen and (max-width:900px){
    .footer_bottom_parent .bottom_center_div{
        width: 100% !important;
    }
}

@media screen and (max-width: 600px){
    .category_list_container{
        width:  92% !important;
    }
}

  .subscription-box {
    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    background: #fff;
    max-width: 400px;
    /* width: 100%; */
    border-radius: 12px;
    text-align: center;
    padding: 20px 30px;
    /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15); */
  }
  
  .subscription-box .top {
    height: 140px;
    background: #1414b8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px 12px 0 0;
    position: relative;
  }
  
  .subscription-box .top::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    background: #1414b8;
    bottom: -10px;
    transform: rotate(45deg);
  }
  
  .subscription-box .top i {
    font-size: 70px;
    color: #ccc;
  }
  
  .subscription-box .bottom {
    padding: 30px;
  }
  
  .subscription-box .bottom .info {
    font-size: 20px;
    font-weight: 500;
  }
  
  .subscription-box .bottom .input-box {
    width: 100%;
    height: 45px;
    margin: 30px 0 100px 0;
  }
  
  .subscription-box .bottom .input-box input {
    width: 100%;
    height: 100%;
    font-size: 18px;
    border-radius: 25px;
    margin-top: 15px;
    outline: none;
    transition: all 0.4s ease;
  }
  
  .subscription-box .bottom input[type="text"] {
    padding-left: 20px;
    border: 2px solid #cccccc;
  }
  
  .subscription-box .bottom input[type="text"]:focus,
  .subscription-box .bottom input[type="text"]:valid {
    border-color: #CC0201;
  }
  
  .subscription-box .bottom input[type="submit"] {
    border: none;
    background: #CC0201;
    color: #ffffff;
    letter-spacing: 1px;
    cursor: pointer;
  }
  
  .subscription-box .bottom input[type="submit"]:hover {
    background: #CC0201;
  }
  
  .subscription-box .bottom .footer {
    font-size: 16px;
  }
  