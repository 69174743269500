.more_dropdown ul .list-item .header_item_link:hover{
    background: rgba(235, 233, 233, 0.959);
}
.category_header{
    position: relative;
    top: 142px;
    /* width: 100%; */
    z-index: 900;
    text-align: center;
    min-height: 54px;
   
}
.category_header div ul{
    padding-bottom: 5px !important;
}
.active_div{
    border-bottom: 3.3px solid green;
    background-color: transparent !important;
}
.dropdown_custom_menu{
    height: 220px;
    overflow:hidden;
}
.product_cat_wise_main{
    margin-top: 155px;
}

  /* @media screen and (max-width:900px){
    .category_header{
        position: relative;
        top: 140px !important;
        width: 100%;
        z-index: 900;
        height: 65px;
        overflow: hidden;
        overflow-x: scroll;
    }
    .category_header > div{
        width: 700px;
        max-width: 1000px;
        overflow: hidden;
        overflow-x: auto;
    }
}  */
/* @media screen and (max-width:600px){
  .category_header{
      position: absolute;
      top: 140px !important;
      width: 100%;
      z-index: 900;
      height: 65px;
      overflow: hide;
      /* overflow-x: scroll; */
  /* } 
  .category_header > div{
      width: 390px;
      max-width: 900px;
      overflow: hide;
      overflow-x: auto;
  }
}  */
body {
  overflow-x: hidden;
}
.category_header {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow: hidden;
  white-space: nowrap; 
  width: 98vw;
  min-height: 54px;
  
  }
  .category_header > div{
    max-width: 100%; 
    overflow: hidden;
    white-space: nowrap; 
  }
  .menu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    min-width: 60px;
     margin-right: 5px; 
     position: relative;
    
  }
 .menu-item-content{
    display: flex;
    align-items: center;
    padding: 0;
  }
  .menu-item.active {
    border-bottom: 2px solid #007bff; 
  }
  .category-image {
    width: 40px; 
    height: 40px; 
    margin-right: 2px; 
    vertical-align: middle; 
  }
  .header_item_link {
    font-size: 14px !important;
    text-align: center;
    text-decoration: none;
    color: #333; 
  }
  @media screen and (max-width: 600px) {
    .header_item_link{
    font-size: 9px !important ;
    } 
    .category-image {
      width: 20px; 
      height: 20px; 
    }
    .category_header{
      min-height: 31px ;
    }
  }
  @media screen and(max-width: 900px) {
    .header_item_link{
    font-size: 10px !important;
    }
    .category_header{
      min-height: 31px;
    }
  }
  @media screen and (max-width: 1024px) {
    .header_item_link{
    font-size: 9px !important;
    }
  }
  .slick-slider {
    width: 100%;
  }
  .slick-slide {
    padding: 0 0px; 
  }
  .menu-item-content:hover
{
  background-color: #eee;
}
  
  .slick-prev,
  .slick-next {
    font-size: 0; 
    width: 40px; 
    height: 40px; 
    margin-left: 1%;
    margin-right: 1%;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: auto;
    
  }
  .slick-next:before{
    content: "\2192";
    color: grey;
    display: block;
    text-align: center;
    position: relative;
    margin-left: 1%;
    margin-right: 1%;
  }
  .slick-prev:before
   {
    content: "\2190"; 
    color: grey; 
    margin-right: 15px;
    margin-left: 1%;
   
  }
  @media screen and (max-width: 300px){
   .category-image{ 
    width: 40px; 
    height: 40px; 
  }
  }
  @media screen and (max-width: 600px){
    .slick-prev:before{
      margin-right: 1px;
      position: relative;
      left:3px;
    }
    .slick-next:before{
      margin-left: -58px !important;
    }
    .slick-prev,
    .slick-next {
      margin-left: 1px !important;
      margin-right: 1px !important;
    }
  }
  .slick-prev:hover
  .slick-next:hover {
    color: #0056b3;
  }
  .menu-item:hover {
    position: relative; 
    transition: text-decoration 0.3s;
  }
  
  .menu-item::before {
    content: "";
    position: absolute;
    width: 40%;
    height: 2px;
    background-color: green;
    bottom: 0;
    left: 0;
    transition: width 0.3s;
    transform: scaleX(0); 
    transform-origin: left; 
  }
  