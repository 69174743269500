.overlay{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.6);
    position: absolute;
    top: 0;
    z-index: 10000;
    left: 0;
}

.total_amount{
    box-shadow: 2px 0 2px 2px rgb(247, 246, 246);
}

.cart_container_select_payment{
    height: auto !important;
    margin-bottom: 100px;
}

.payment_methods img{
    padding: 10px;
    border: 1px solid rgb(222, 222, 222);
    border-radius: 10px;
    height: 110px;
    width: 110px !important;
}

.img_select img{
    border: 2px solid rgb(186, 180, 180);
}

.payment_methods_heading{
    background-color: rgb(238, 236, 236) !important;
    padding: 6px 0;
    border-radius: 8px;
}

.place_order_btn{
    width: 94%;
    display: block !important;
}

.place_order_btn:disabled{
    background-color: gray !important;
}

