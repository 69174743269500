.category_parent{
    min-height: 220px;
    height: auto;
    display: flex;
    flex-wrap:wrap;
    /* justify-content:space-between; */
}

.category_parent .category_card{
    width: 118px !important;
    height: 120px;
}

.category_parent .category_card .product{
    width: 65px !important;
    height: auto !important;
    border-radius: 10px;
    max-height: 84px; 
    box-shadow: 2px 2px 3px #999;
}

.category_parent .category_card .category_name p{
    font-size: 15px !important;
    margin-left: -15px !important;
}
.t {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

@media screen and (max-width : 900px){
    .category_parent{
        min-height: 160px;
        /* place-content: space-between; */
        margin-left: 15px !important;
        margin-right: -18px !important;
        justify-content: flex-start
    }    
    .category_parent .category_card{
        width: 131px !important;
    }
    .category_parent .category_card .category_name p{
        font-size: 10px !important;
        margin-right: 20px !important ;
        margin-left: 0 !important;
    }
}

@media screen and (max-width : 600px){

    .category_parent .category_card{
       /* margin-inline-end: 10px; */
        width: calc(33.33% - 20px) !important;
        margin: 0 10px 0 0 !important;
    }
    .category_parent{
        min-height: 160px;
        /* place-content: space-between; */
        margin-left: 15px !important;
        margin-right: -18px !important;
        justify-content: flex-start;
    }    
    .category_parent .category_card .category_name p{
        font-size: 10px !important;
        /* margin-right: 20px !important ; */
        position:relative; 
        left: 4px;
    }
    .category_name {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
}

.category_list::-webkit-scrollbar {
    height: 10px;
}

/* Track */
.category_list::-webkit-scrollbar-track {
    height: 10px;
}

/* Handle */
.category_list::-webkit-scrollbar-thumb {
    background: transparent;
    height: 10px;
}