/* .more_dropdown ul .list-item .header_item_link:hover {
  background: rgba(235, 233, 233, 0.959);
}

.category_header {
  position: fixed;
  width: 100%;
  z-index: 900;
  left: 0;
}

.category_header div ul {
  padding-bottom: 0px !important;
}

.active_div {
  border-bottom: 3.3px solid green;
  background-color: transparent !important;
}

.dropdown_custom_menu {
  height: 220px;
  overflow: scroll;
}

.product_cat_wise_main {
  margin-top: 0px;
}

.pagination-symbol {
  font-size: 24px;
  
}

.category-item {
  display: flex;
  align-items: center;
}

.category-image {
  width: 50px;
  height: 40px;
}

.header_item_link {
  font-size: 10px;
}

@media screen and (max-width: 600px) {
  .pagination-symbol {
    font-size: 8px;
    margin: 0;
    color: #000;
  }

  .header_item_link {
    margin-left: 1px;
    margin-right: 10px; 
  }
  .custom-button {
   
    color: grey;
    font-size: 26px;
    padding: 5px 10px;
  
  }
}

.button-wrapper {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  background-color: white;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  left: 0; 
  right: 0;

}

.custom-button {
  
  color: grey;
  font-size: 28px;
  padding: 15px 0px;
  border: none;
  border-radius: 0;
  height: auto;
  width: 50%;
}
.custom-button.next::before {
  content: ">"; 
  transition: transform 0.3s ease;
}
.custom-button.prev::before {
  content: "<"; 
  transition: transform 0.3s ease;
}
.custom-button.next:hover::before {
  transform: rotate(-45deg); 
}
.pagination-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0px; 
}
.custom-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px; 
  padding: 0px; 
}

.menu-item{
  background-color: red;
}
.custom-button.prev:hover::before {
  transform: rotate(45deg); }
@media screen and (max-width: 600px) {
  .custom-button {
    font-size: 26px;
    width: 50%;
    height: auto;
    padding: 5px 10px;
  }

  .header_item_link {
    margin-left: 5px;
  }
  .button-wrapper {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    background-color: white;
    z-index: 1;
    padding: 4px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    left: 0; 
    right: 0;
  }
}








.category-header-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.custom-button {
  color: grey;
  font-size: 38px;
  padding: 15px 10px;
  margin: 0;
  border: none;
  border-radius: 0;
  height: auto;
  width: 48px; /* Adjust the width as needed */
/* } */

/* .custom-button.prev::before {
  content: "<";
  transition: transform 0.3s ease;
}

.custom-button.next::before {
  content: ">";
  transition: transform 0.3s ease;
}

.custom-button.prev:hover::before {
  transform: rotate(45deg);
}

.custom-button.next:hover::before {
  transform: rotate(-45deg);
}

.category-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px; 
  overflow-y: scroll;
}

.list-item {
  cursor: pointer;
}

.category-item {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.category-image {
  width: 40px;
  height: 35px;
  margin-right: 0;
}

.header_item_link {
  font-size: 18px;
}

  */
  
.menu-item {
 
   display: flex;
   align-items: center;
   cursor: pointer;
   position: relative;
   margin-bottom: 2px;
  
}
.menu-item-content{
  display: flex;
  align-items: center;
  
}
.menu-item-content:hover
{
  background-color: #eee;
}
.menu-item.active {
  border-bottom: 2px solid #007bff; 
}


.category-image {
  width: 40px; 
  height: 40px; 
  margin-right: 2px; 
  vertical-align: middle; 
}


.header_item_link {
  font-size: 14px !important;
  text-align: center;
  text-decoration: none;
  color: #333; 
}

.header_item_link:hover{
  border-bottom: 3.3px solid green;
} 
.slick-list{
  width: 100%   ;
  padding-left: 1% !important;
  overflow: hidden;
  margin: 0;
}

.slick-slider {
  width: 95%;
}
.slick-slide > div {
  margin: 0;
}
.slick-slide {
  width: 600px;
  padding: 0 2px; 
}
.slick-prev,
.slick-next {
  font-size: 0; 
    width: 40px; 
    height: 40px; 
    display: flex;
    justify-content: end;
    align-items: flex-end;
    cursor: pointer;
    transition: background-color 0.3s;
}
.slick-next:before{
content: "\2192";
color: grey;
}
.slick-prev:before
 {
  content: "\2190";
  color: grey;
  }
 
.slick-prev:hover,
.slick-next:hover {
  color: #0056b3;
}

.menu-item:hover {
  position: relative; 
  
}
.menu-item::before {
  content: "";
  height: 2px;
  bottom: 0;
  left: 0;
  
}
