.brands_card_container{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.brands_card_container .row{
    padding-left: 15px !important;
}

.brand_page_main{
    margin-top: 120px !important;
}

.brand_card_image{
    height: 90px;
    width: 90px;
    padding: 5px;
}
.line-through-title
{
    text-align: center;
    align-items:center;
    -webkit-box-align: center;
    -ms-flex-align: center;
}
.line-through-title::before,.line-through-title::after
{
    content: "";
    display: inline-block;
    width: 60px;
    height: 2px;
    background: #222;
    margin:0px 5px 6px 6px;
}

.partners{
    width: 100%;
}

.brand_card_parent{
    width: 123px !important;
}

.search_bar_container{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
}

.brand_sorting{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    width: 280px;
    cursor: pointer;
}

.brand_sorting .sorting_select:focus{
    box-shadow: none !important;
}

@media screen and (max-width:900px){
    .brand_page_main{
        margin-top: 180px !important;
    }
    .brands_card_container .row{
        padding-left: 25px !important;
    }  
}

@media screen and (max-width:900px){
    .brand_card_parent{
        width: 111px !important;
    }
    .brands_card_container .row{
        padding-left: 0px !important;
    } 
}

@media screen and (max-width:600px){
    .brand_sorting{
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 8px;
        width: 92%;
        cursor: pointer;
    }
}
