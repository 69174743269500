
.acount_Dropdown{
  width:250px;
  z-index:100000;
  top:50px;
  position:absolute !important;
  border-radius:0 0 15px 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.common_header{
  padding-top:50px;
}
.contact_strip{
  width: 100%;
  position: absolute;
  height:25px;
  top:0;
  background:#CC0201;
  text-align: right;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
}

.contact_strip span {
  margin-right: 20px;
}

@media screen and (max-width:900px){
  .acount_Dropdown{
    width:200px;
    z-index:100000;
    top:50px;
      right:10px ;
      border-radius:0 0 15px 15px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
    .suggestion_Container .product_list {
      height: 50px;
    }
  }
  @media screen and (max-width: 600px){
    .header_mobile_logo img{
      width:80px !important;
      margin-top: 20px;
    }
      .contact-number
    {
        position: absolute;
        top: -28px;
        right: 20px;
    }
    .suggestion_Container{
      width:200px;
    }
    .contact_strip{
      font-size: 9px;      
      text-align: center;
    }
    .contact_strip span {
      margin-right: 5px;
    } 
    .font_sub_heading{
      font-size:15px !important;
    }
    .common_header
    {
      padding-right: 0 !important;
    }
    .user_details .profile_img
    {
      width: 30px !important;
      height: 30px !important;
      margin-left: 7px;
      margin-bottom:40px;
      padding: 0 !important;
    }
    .bFHCDW{
      /* margin-bottom: 18px; */
    }
    .user_details{
      position:relative;
      height: 30px;
      bottom:20px;
    }
  }
[title]:after{
  background: #333;
}

[title]:hover:after{
  background: #333;
}
